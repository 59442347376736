import { Elm } from './src/AllBrowserNotebook.elm'

var app = Elm.AllBrowserNotebook.init({
  node: document.querySelector('main')
});


app.ports.sendGetReport.subscribe(function ({ requestId: id, body: message }) {
  BosatsuReport.getReport(message).then(function (response) {
    console.log({ requestId: id, body: response })
    app.ports.receiveDocument.send({ requestId: id, body: response })
  })
});


app.ports.sendPostData.subscribe(function ({ requestId: id, body: message }) {
  BosatsuReport.postCache(message).then(function (response) {
    console.log({ requestId: id, body: response })
    app.ports.receiveData.send({ requestId: id, body: response })
  })
});